<template>
	<div class="ui grid">

		<div class="row centered position-relative">
			<div class="eight wide column">
				<h1 class="dossier-welcome text-capitalize">Bonjour {{current_user.nom}}</h1>
			</div>
		</div>
		<div class="row centered">
			<button class="ui button actualiser-button" @click="actualiser()">
				<i class="ui icon refresh"/>
				Actualiser
			</button>
		</div>
		<div class="row centered">
			<div class="eight wide computer twelve wide mobile column">
				<h2 class="dossier-section-title">ESPACE SUIVI D'ETUDE</h2>
			</div>
		</div>

		<!-- process -->
		<div class="row centered">
			<div class="twelve wide column">
				<horizontal-process/>
			</div>
		</div>

		<!-- action-component -->
		<div class="row centered">
			<div class="twelve wide column">
				<div class="dossier-suivi-container">
					<component :is="component"/>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="wide column">
				<div>
					<img src="@/assets/images/booksIcon.svg" alt="">
				</div>
			</div>
		</div>

	</div>
</template>

<script>
    import horizontalProcess from "@/components/etude/suiviEtude/horizontalProcess.vue";
    // actions components
    import annee1 from "@/components/etude/suiviEtude/steps/annee1.vue";
    import global_accept from "@/components/etude/suiviEtude/steps/global_accept.vue";


    export default {
		name: "suiviDemandes",
		components: {
			horizontalProcess,
			annee1,
			global_accept
		},
		computed: {
			current_user() {
				return this.$store.state.current_user
			},
			current_step() {
				return this.$store.getters.current_step
			//	return !_.isEmpty(this.$store.state.dossier)? this.$store.getters.current_step : {};

			},
      component(){
        return !_.isEmpty(this.current_step) ? this.current_step.component : 'global_accept'
      }
		},
		methods:{
			actualiser(){
				this.$store.dispatch("get_dossier")
				//this.$store.state.current_process = 'etude';
				//this.$store.dispatch("get_steps");
			}
		},
		mounted() {
			this.$store.dispatch("get_dossier")
			//this.$store.state.current_process = 'etude';
			this.$store.dispatch("get_steps");
		}
	}
</script>

<style>

	.dossier-suivi-container {
		background-color: white;
		padding: 2em;

		box-shadow: 0px 0px 33px -8px rgba(0, 0, 0, 0.25);
		border-radius: 9px;
	}

	.suivi-card-title {
		color: #CDDEFF;
		text-transform: uppercase;
		font-weight: 800;
		font-size: 1.75em;
		margin-bottom: 0.5em;
	}

	.suivi-card-message {
		font-size: 1.5em;
		color: var(--main-color-blue);
	}
	.rejected_message {
		font-size: 1.5em;
		color: red;
	}

	.success-suivi {
		margin: 3em;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.success-suivi > h3 {
		color: var(--main-color-green);
		font-size: 2.25em;
		font-weight: 800;
		text-transform: uppercase;
		margin: 0em;

	}

	.success-suivi > svg {
		margin-right: 1em;
		height: 44px;
		width: 44px;

	}
</style>
