<template>
    <div class="ui grid">

        <!--header-->
        <div class="ui row">
            <div class="sixteen wide column">
                <div class="suivi-card-title">{{current_step.label}}</div>
            </div>
        </div>

        <!--content-->
        <div class="ui row">
            <div class="sixteen wide center aligned column">
                <p :class="current_step.status==='rejected'? 'rejected_message' : 'suivi-card-message'">
                    {{message}}
                </p>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "etude.suiviEtude.etude.annee1",
        computed: {
            current_step() {
                return this.$store.getters.current_step
            },
            message() {
                let msg = this.current_step.label + " encours ..."
                if (this.current_step.status === "rejected") {
                    msg = "Malheureusement vous êtes exclus";
                }
                return msg;
            }
        }
    }
</script>
