<template>
    <div class="ui grid">

        <!--header-->
        <div class="ui row">
            <div class="sixteen wide column">
                <div class="suivi-card-title">Decision du jury</div>
            </div>
        </div>

        <!--content-->
        <div class="ui row">
            <div class="sixteen wide center aligned column">
                <p class="suivi-card-message" style="text-transform: uppercase;font-weight: 700;color: #24ab77;font-size:22px">
                    <i class="check large circle icon" style="font-size: 2em" />
                    Félicitations pour l'obtention de votre doctorat
                </p>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "suiviEtude.steps.global_accept"
    }
</script>
